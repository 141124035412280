<template>
  <div class="activity-center">
    <!-- 活动中心二级页，隐藏大咖与大咖面对面模块，
  需求单：http://tapd.oa.com/GamingCommunity/prong/stories/view/1020419237862902649 -->
    <!-- <div class="activity-center-qa">
    <div class="activity-center-qa-title">
      <span class="activity-center-qa-title-text">大咖面对面</span>
      <div class="activity-center-qa-title-history" @click.stop="$checkAppDeco(showQaHistory)">
        往期回顾
      </div>
    </div>
    <Swiper ref="bannerSwiper" :options="swiperOption" class="activity-center-qa-banner">
      <swiper-slide v-for="(banner, index) in bannerList"
        :key="index"
      >
        <banner-item
          :icon-url="banner.iconUrl"
          :jump-url="banner.jumpUrl"
          :title="banner.title"
          :member-count="banner.memberCount"
          :begin-time="banner.beginTime"
          :status="banner.curStatus"
        />
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination" />
    </Swiper>
  </div>
  <div class="activity-center-dividing" /> -->
    <div class="welfare-title">
      <span class="welfare-title-text">热门福利</span>
      <div
        @click="jumpToWelfareCenter"
        class="welfare-more"
      >
        <span class="welfare-more-entrance">查看其它</span>
        <span class="welfare-more-entrance-num">{{ '\xa0' + totalGiftNum + '\xa0' }}</span>
        <span class="welfare-more-entrance">个礼包</span>
        <span class="icon-arrow-right" />
      </div>
    </div>
    <swiper
      ref="welfareBannerSwiper"
      :options="welfareSwiperOption"
      class="welfare-banner"
    >
      <swiper-slide
        v-for="(welfareGroup, index) in welfareList"
        :key="index"
      >
        <div class="welfare-banner-container">
          <WelfareItem
            v-for="(welfare) in welfareGroup"
            :key="welfare.gameCode"
            :game-code="welfare.gameCode"
            :game-icon="welfare.gameIconUrl"
            :game-name="welfare.gameName"
            :game-gift-packs="welfare.num"
            :game-gifts="welfare.giftItem"
          />
        </div>
      </swiper-slide>
      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
    <div class="activity-center-online-title">
      线上活动
    </div>
    <Status
      v-if="isError || (isEnd && isEmpty)"
      :status="status"
      :text="text"
    />
    <ScrollView
      :loading="isLoading"
      :error="isError"
      :no-more="isEnd"
      :no-more-text="activityList.length ? '活动已经全部为你呈现了哦' : ''"
      @reachBottom="onReachBottomHandler"
      @bottomErrorClick="onBottomErrorClickHandler"
    >
      <div class="activity-center-activity-list">
        <activity-item
          v-for="(item, index) in activityList"
          :key="index"
          :act-icon="item.actIcon"
          :act-title="item.actTitle"
          :start-time="item.startTime"
          :end-time="item.endTime"
          :act-url="item.actUrl"
          :status="item.status"
          :report-info="{
            ...commonReportInfo,
            indexid: index + 1,
            contentid:item.actId,
            moduleid: 3,
            eventtype: 3,
            extendid: 1,
            ext5: item.gid || 0,
          }"
        />
      </div>
    </ScrollView>
  </div>
</template>

<script>
// import PostLoading from '@/component/post-loading/index.vue';
import 'swiper/dist/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ScrollView from '@/ui/scroll-view/index.vue';
import jsBridge, {
  setRightButton, showShareDialog, openView,
} from '@/jsbridge/index';
import {
  // getActCenterBannerList,
  getActivityList,
} from '@/api/activity';
import Status from '@/ui/status';
import { checkEnv } from '@/util/browser-env';
import { setH5Share } from '@/util/h5-share';
import reportApi, { reportEnum } from '@/api/report';
// import { throttle } from '@/util/util';
// import BannerItem from './component/BannerItem.vue';
import ActivityItem from './component/ActivityItem.vue';
import WelfareItem from './component/WelfareItem.vue';
import unicodeString from '@/util/unicode-string';

const envObject = checkEnv();
let activityCenterVm = null;

// 这里的上下文是插件内，所以一些变量只能挂到window下
const exposureBannerIdList = [];
const globalBannerList = [];
const bannerLen = 0;
const exposureWelGameIdList = [];

export default {
  name: 'ActivityCenter',
  components: {
    Swiper,
    SwiperSlide,
    ScrollView,
    // BannerItem,
    WelfareItem,
    ActivityItem,
    Status,
  },
  data() {
    const vueThis = this;
    return {
      totalGiftNum: 0,
      welfareList: [],
      // bannerList: [],
      activityList: [],
      activityIdList: [],
      isLoading: false,
      isEmpty: false,
      isError: false,
      isEnd: false,
      nextActId: 0,
      status: 'empty',
      text: '',
      buttonTitle: '',
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        clicks: {
          preventClicksPropagation: true,
        },
        initialSlide: 1,
        spaceBetween: 18,
        autoplay: true,
        loop: true,
        speed: 1000,
        on: {
          slideChangeTransitionEnd() {
            // Banner的曝光上报
            if (bannerLen > 1) {
              const curIndex = (this.activeIndex !== bannerLen ? this.activeIndex % bannerLen : this.activeIndex);
              const { activityId } = globalBannerList[curIndex - 1];
              if (!exposureBannerIdList.includes(activityId)) {
                const reportInfo = {
                  indexid: curIndex,
                  pageid: reportEnum.pageid.ACTIVITY_CENTER,
                  contentid: activityId,
                  moduleid: 2,
                  eventtype: 2,
                  extendid: 1,
                };
                exposureBannerIdList.push(activityId);
                reportApi(reportInfo, '活动中心Banner曝光上报');
              }
            }
          },
          tap() {
            // 这里的上下文不是Vue的上下文，需要单独处理绑定事件，要不然会出现循环的banner点击无效的问题
            const curIndex = (this.activeIndex !== bannerLen ? this.activeIndex % bannerLen : this.activeIndex);
            const { activityId, jumpUrl } = globalBannerList[curIndex - 1];
            activityCenterVm.$checkAppDeco(() => {
              openView({ options: jumpUrl });
            }, jumpUrl);

            const reportInfo = {
              indexid: curIndex,
              pageid: reportEnum.pageid.ACTIVITY_CENTER,
              contentid: activityId,
              moduleid: 2,
              eventtype: 3,
              extendid: 1,
            };
            reportApi(reportInfo, '活动Banner点击');
          },
        },
      },
      welfareSwiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          update() {
            vueThis.reportWelfExposure(0, '福利模块-礼包曝光');
          },
          activeIndexChange() {
            vueThis.reportWelfExposure(this.realIndex, '福利模块-礼包曝光');
          },
        },
      },
      shareContent: {
        title: '闪现一下活动中心',
        summary: '快来看看闪闪为你准备的精彩活动吧～',
        targetUrl: `https://${window.location.host}/activity-center/index`,
        thumbUrl: 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
      },
      commonReportInfo: {
        indexid: 1,
        pageid: reportEnum.pageid.ACTIVITY_CENTER,
      },
      exposureIdList: [],
      exposureComputing: false,
    };
  },
  watch: {
    isError(newValue) {
      if (newValue) {
        this.status = 'fail';
        this.text = '';
      }
    },
    isEmpty(newValue) {
      if (newValue) {
        this.status = 'empty';
        this.buttonTitle = '';
        this.text = '内容正在快马加鞭生产中';
      }
    },
  },
  created() {
    activityCenterVm = this;
  },
  mounted() {
    // this.loadQaLiveList();
    this.loadActivityList(0);
    this.initShare();
    this.reportData({
      moduleid: 1,
      eventtype: 1,
      extendid: 1,
      prev_id: this.$route.query.prev_id,
    }, '活动中心页面pv上报');
    this.initScrollEvent();
  },
  methods: {
    /**
       * 拉取策划面对面活动列表
       * */
    // loadQaLiveList () {
    //   getActCenterBannerList().then((result) => {
    //     if (result.ret === 0) {
    //       this.bannerList = result.bannerList;
    //       globalBannerList = this.bannerList;
    //       bannerLen = this.bannerList.length;
    //       console.log('bannerList', globalBannerList);
    //       if (bannerLen > 0) {
    //         // banner第一位曝光在这里上报
    //         const { activityId } = globalBannerList[0];
    //         this.reportData({
    //           contentid: activityId,
    //           moduleid: 2,
    //           eventtype: 2,
    //           extendid: 1,
    //         }, '活动中心Banner曝光上报');
    //         exposureBannerIdList.push(activityId);
    //       }
    //     }
    //   });
    // },
    /**
       * 拉取活动列表
       * */
    loadActivityList(nextActId) {
      if (!this.isEnd) {
        // 超时则提示异常
        const errorTimer = setTimeout(() => {
          this.isError = true;
        }, 10000);

        getActivityList({
          pageSize: 20,
          nextActId,
        }).then((result) => {
          if (result.ret === 0) {
            clearTimeout(errorTimer);
            if (result.totalGiftNum) {
              this.totalGiftNum = result.totalGiftNum;
            }
            if (result.fuliGiftItem) {
              this.welfareList = [];
              result.fuliGiftItem.forEach((item, index) => {
                const groupIndex = Math.floor(index / 4); // 每页4个
                if (groupIndex > 2) { // 最多3页
                  return;
                }
                if (!this.welfareList[groupIndex]) {
                  this.welfareList.push([]);
                }
                this.welfareList[groupIndex].push(item);
              });
            }

            const beginActList = result.actList.filter(item => item.status === 0);
            beginActList.forEach((item) => {
              if (!this.activityIdList.includes(item.actId)) { // 去重
                this.activityIdList.push(item.actId);
                this.activityList.push(item);
              }
            });
            this.isEnd = (Number(result.isEnd) === 1);
            this.nextActId = result.nextActId;
            this.isEmpty = beginActList.length === 0 && this.isEnd;
            this.isError = false;
          } else if (this.nextActId === 0) {
            this.isError = true;
          }
        })
          .then(() => {
            if (nextActId === 0) {
              this.scrollHandler(true); // 触发曝光上报
            }
          })
          .catch(() => {
            if (this.nextActId === 0) {
              this.isError = true;
            }
          });
      }
    },
    onReachBottomHandler() {
      this.loadActivityList(this.nextActId);
      // console.log('onReachBottomHandler');
    },
    onBottomErrorClickHandler() {
      window.location.reload();
      // this.activityList = [];
      // this.activityIdList = [];
      // if (bannerLen === 0) { // bannner加载完则不触发
      //   this.loadQaLiveList();
      // }
      // this.loadActivityList(0);
      // console.log('onBottomErrorClickHandler');
    },
    showQaHistory() {
      this.reportData({
        moduleid: 2,
        eventtype: 3,
        extendid: 2,
      }, '查看往期活动');
      jsBridge.ui.openPopWebview({
        url: `https://${window.location.host}/face-to-face/history`,
      });
    },
    initShare() {
      if (envObject.isApp) {
        setRightButton({
          callback: () => {
            showShareDialog(this.shareContent);
            this.reportData({
              moduleid: 4,
              eventtype: 1,
              extendid: 1,
            }, '分享活动');
          },
        });
      } else {
        setH5Share({
          shareTitle: this.shareContent.title,
          shareDesc: unicodeString.slice(this.shareContent.summary, 200),
          shareLink: this.shareContent.targetUrl,
          shareImgUrl: this.shareContent.thumbUrl,
        });
      }
    },
    // 数据上报函数
    reportData(params, desc) {
      const obj = {
        ...this.commonReportInfo,
        ...params,
      };
      reportApi(obj, desc);
    },
    getScrollEventTarget(element) {
      let currentNode = element;
      // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
      while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
        const { overflowY } = getComputedStyle(currentNode);
        if (overflowY === 'scroll' || overflowY === 'auto') {
          return currentNode;
        }
        currentNode = currentNode.parentNode;
      }
      return window;
    },
    initScrollEvent() {
      const el = this.getScrollEventTarget(this.$el);
      el.addEventListener('scroll', this.scrollHandler);
    },
    scrollHandler(firstLoad = false) {
      if (!firstLoad) { // 不是首次触发的曝光数据，需要做节流
        const me = this;
        if (me.exposureComputing) {
          return;
        }
        me.exposureComputing = true;
        setTimeout(() => {
          me.exposureComputing = false;
        }, 300);
      }
      const me = this;
      const nodes = document.querySelectorAll('.activity-center-activity-list-item');
      nodes.forEach((node, index) => {
        if (!this.exposureIdList.includes(index) && this.isElementInViewport(node)) {
          this.exposureIdList.push(index);
          const { actId, gid } = this.activityList[index];
          me.reportData({
            indexid: (index + 1),
            contentid: actId,
            moduleid: 3,
            eventtype: 2,
            extendid: 1,
            ext5: gid,
          }, '活动中心列表曝光上报');
        }
      });

      // heightOfComponents
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();

      const widthSt = rect.width / 2;
      const heightSt = rect.height / 2;

      const { innerHeight } = window;
      const { innerWidth } = window;


      if ((rect.top <= 0 && rect.height > innerHeight)
          || (rect.left <= 0 && rect.width > innerWidth)
      ) {
        return rect.left * rect.right <= 0
            || rect.top * rect.bottom <= 0;
      }

      return (
        rect.height > 0
          && rect.width > 0
          && ((rect.top >= 0 && rect.top <= innerHeight - heightSt)
            || (rect.bottom >= heightSt && rect.bottom <= innerHeight))
          && ((rect.left >= 0 && rect.left <= innerWidth - widthSt)
            || (rect.right >= widthSt && rect.right <= innerWidth))
      );
    },
    jumpToWelfareCenter() {
      const reportInfo = {
        pageid: reportEnum.pageid.ACTIVITY_CENTER_WELF,
        moduleid: 87,
        eventtype: 3,
        extendid: 1,
      };
      this.reportData(reportInfo, '福利模块-更多按钮点击');
      openView({ options: 'tgc://native?moduleName=pointsmall&showGamePanel=1' });
    },
    reportWelfExposure(curIndex, dec) {
      const welfPage = this.welfareList[curIndex];
      if (welfPage) {
        welfPage.forEach((v) => {
          if (exposureWelGameIdList.includes(v.gameCode)) {
            return;
          }
          const reportInfo = {
            pageid: reportEnum.pageid.ACTIVITY_CENTER_WELF,
            appid: v.gameCode,
            moduleid: 86,
            eventtype: 2,
            extendid: 1,
          };
          exposureWelGameIdList.push(v.gameCode);
          this.reportData(reportInfo, dec);
        });
      }
    },
  },
};

</script>

<style lang="scss" src="./scss/index.scss"></style>
