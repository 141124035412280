<template>
  <div
    @click.stop="$checkAppDeco(jumpTo, actUrl);reportApi(reportInfo,'活动列表点击');"
    class="activity-center-activity-list-item"
  >
    <div class="activity-center-activity-list-item-img-container">
      <img :src="actIcon">
    </div>
    <div class="activity-center-activity-list-item-game">
      <div class="activity-center-activity-list-item-game-bg" />
    </div>
    <div class="activity-center-activity-list-item-info">
      <div class="activity-center-activity-list-item-info-title-wrap">
        <div
          :class="classObject"
          class="activity-center-activity-list-item-info-title-wrap-status"
        />
        <div class="activity-center-activity-list-item-info-title-wrap-title">
          {{ actTitle }}
        </div>
      </div>
    </div>
    <div class="activity-center-activity-list-item-subtitle">
      {{ info }}
    </div>
    <div class="activity-center-activity-list-item-gap" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { openView } from '@/jsbridge/index';
import reportApi from '@/api/report';
import { appendParam } from '@/util/util';
import { parseUrl } from '@/util/uri';

export default {
  name: 'ActivityItem',
  props: {
    actIcon: {
      type: String,
      default() {
        return '';
      },
    },
    actTitle: {
      type: String,
      default() {
        return '';
      },
    },
    startTime: {
      type: String,
      default() {
        return '';
      },
    },
    endTime: {
      type: String,
      default() {
        return '';
      },
    },
    actUrl: {
      type: String,
      default() {
        return '';
      },
    },
    status: { // 0-准备就绪 1-预约中 2-进行中 3-已结束
      type: Number,
      default() {
        return 0;
      },
    },
    reportInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    info() {
      const thisYear = new Date().getFullYear();
      const startYear = new Date(this.startTime * 1000).getFullYear();
      const endYear = new Date(this.endTime * 1000).getFullYear();
      return `活动时间：${dayjs(this.startTime * 1000).format(thisYear === startYear ? 'MM-DD HH:mm' : 'YYYY-MM-DD HH:mm')} 至 ${dayjs(this.endTime * 1000).format(thisYear === endYear ? 'MM-DD HH:mm' : 'YYYY-MM-DD HH:mm')}`;
    },
    classObject() {
      return {
        ongoing: this.status === 0,
        booking: this.status === 1,
        end: this.status === 2,
      };
    },
  },
  methods: {
    jumpTo(url) {
      const { userInfo } = this.$store.state.user;
      let newUrl = url;
      const { hostname, pathname, protocol } = parseUrl(newUrl);
      if (hostname === 'pay.qq.com' && pathname === '/h5/shop.shtml' && userInfo?.uid !== 0) {
        newUrl = protocol === 'http:' ? newUrl.replace('http', 'https') : newUrl;
        const urlParams =  this.$store.getters['app/gamePayLoginParams'];
        newUrl = appendParam(newUrl, {
          ...urlParams,
        });
      }
      openView({ options: newUrl });
    },
    reportApi,
  },
};
</script>

<style lang="scss" src="../scss/index.scss"></style>
