<template>
  <div
    @click="jumpToWelfareCenter"
    class="welfare-banner-item"
  >
    <div class="welfare-banner-item-game">
      <img
        :src="gameIcon"
        :alt="gameName"
        class="welfare-banner-item-game-icon"
      >
      <div class="welfare-banner-item-game-name ui-nowrap">
        {{ gameName }}
      </div>
    </div>
    <div class="welfare-banner-item-gifts">
      <img
        v-for="(gift, index) in gameGifts.slice(-4)"
        :key="index"
        :src="gift.url"
        :alt="gift.description"
        class="welfare-banner-item-gift-icon"
      >
      <span class="welfare-banner-item-gift-text">{{ gameGiftPacks }}个礼包</span>
    </div>
  </div>
</template>

<script>
import { openView } from '@/jsbridge';
import reportApi, { reportEnum } from '@/api/report';

export default {
  name: 'WelfareItem',
  props: {
    gameCode: {
      type: String,
      default() {
        return '';
      },
    },
    gameIcon: {
      type: String,
      default() {
        return '';
      },
    },
    gameName: {
      type: String,
      default() {
        return '';
      },
    },
    gameGiftPacks: {
      type: Number,
      default() {
        return 0;
      },
    },
    gameGifts: {
      type: Array,
      default() {
        return [];
      },
    },
    reportInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
  },
  methods: {
    jumpTo(url) {
      openView({ options: url });
    },
    jumpToWelfareCenter() {
      const reportInfo = {
        pageid: reportEnum.pageid.ACTIVITY_CENTER_WELF,
        appid: this.gameCode,
        moduleid: 86,
        eventtype: 3,
        extendid: 1,
      };
      reportApi(reportInfo, '福利模块-礼包点击');
      this.jumpTo(`tgc://native?moduleName=pointsmall&initGameCode=${this.gameCode}`);
    },
    reportApi,
  },
};
</script>

<style lang="scss" src="../scss/index.scss"></style>
