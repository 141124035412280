/**
 * @file 字符串处理函数。填一下js处理unicode字符的坑
 * 一些特殊字符，例如emoji表情，UTF-16编码中占用4个字节，js会认为是两个字符，导致有很多坑
 * 例如 slice 截取字符串，可能会从emoji中间截取，导致乱码，终端会解析失败
 * slice、reverse等操作字符串 都会有问题 ，可参考（http://www.alloyteam.com/2016/12/javascript-has-a-unicode-sinkhole/）
 */

/**
 * 匹配UTF-16的代理对（surrogate pair）
 * 在UTF-16中被编码为一对16比特长的码元（即32位，4字节），称作代理对（Surrogate Pair)
 * 参考 https://zh.wikipedia.org/wiki/UTF-16#%E4%BB%8EU+D800%E5%88%B0U+DFFF%E7%9A%84%E7%A0%81%E4%BD%8D
 */
const surrogatePairRegexp = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
const isHighSurrogates = char => /[\uD800-\uDBFF]/.test(char);
// const isLowSurrogates = char => /[\uDC00-\uDFFF]/.test(char);

export function len(string) {
  // 把占两个字符的unicode变成一个字符
  return string.replace(surrogatePairRegexp, '_').length;
}

/**
 * 截取字符串
 * @param {String} string 待处理字符串
 * @param {Number} len 截取长度
 */
export function slice(string, length) {
  if (!length) return string;
  // 简单处理，最后一个字符是截断的就去掉
  let result = string.slice(0, length);
  if (isHighSurrogates(result[result.length - 1])) {
    result = result.slice(0, -1);
  }
  return result;

  // 根据真实的字符判断长度进行截取
  // 这种方法会把emoji表情算作一个字符，为了避免不同的理解
  // let pos = 0;
  // let charCount = 0;
  // while (charCount < len && pos < string.length) {
  //   if (isHighSurrogates(string[pos])) {
  //     pos++;
  //   } else {
  //     pos++;
  //     charCount++;
  //   }
  // }
  // return string.slice(0, pos);
}

export default {
  slice, len,
};

